.prod__grid {
	margin-bottom: 54px;
	@include min768 {
		display: flex;
		align-items: flex-start;
		margin-bottom: 80px;
	}
}

.prod__pic {
	width: 100%;
	position: relative;
	display: flex;
	margin-bottom: 37px;
	justify-content: center;

	@include min768 {
		width: 55%;
		padding-right: 30px;
		margin-bottom: 0;
		justify-content: flex-start;
	}
	@include min960 {
		padding-right: 50px;
	}
}

.thumb__swiper {
	overflow: hidden;
	margin-right: 30px;
	width: 80px;
	min-width: 80px;
	height: 350px;
	display: none;

	@include min960 {
		display: block;
	}
}

.prod__thumb {
	min-width: 80px;
}

.prod__thumb-item {
	width: 80px;
	height: 80px;
	padding: 10px;
	overflow: hidden;
	margin-bottom: 10px;
	cursor: pointer;
	border: 2px solid #fff;
	border-radius: 7px;

	&.prod__thumb-item_current {
		border-color: $pink;
	}

	img {
		max-width: 100%;
		height: auto;
	}
}

.photo__swiper {
	overflow: hidden;
	max-width: 450px;
}

.prod__photo {
	
	img {
		max-width: 100%;
		height: auto;
	}
}

.prod__photo-pager {
	margin-top: -25px;
	position: relative;
	z-index: 1;
	text-align: center;

	.swiper-pagination-bullet {
		width: 10px;
		height: 10px;
		border: 1px solid $gray;
		background-color: transparent;
		opacity: 1;

		&.swiper-pagination-bullet-active {
			background-color: $gray;
		}
	}

	@include min768 {
		display: none;
	}
}

.prod__aside {
	width: 100%;

	@include min768 {
		width: 45%;

	}
}

.prod__aside-row {
	&.prod__aside-row_label {
		display: flex;
		margin-bottom: 30px;

		@include min768 {
			display: none;
		}
	}

	&.prod__aside-row_price {
		display: flex;
		align-items: center;
		flex-direction: row;
		margin-bottom: 15px;

		@include min768 {
			flex-direction: column;
			align-items: flex-start;
		}
	}
	&.prod__aside-row_bonus {
		margin-bottom: 30px;
	}
	
}

.prod__price {
	font-weight: 500;
	font-size: 20px;
	margin-bottom: 20px;
	margin-right: 9px;

	@include min375 {
		margin-right: 29px;
		font-size: 30px;
	}

	@include min768 {
		display: flex;
		align-items: center;
	}

	span {
		white-space: nowrap;

		&:first-child {
			display: block;

			@include min768 {
				margin-right: 14px;
			}
		}
		&.prod__price_discount {
			color: $red;
		}
		&.prod__price_strike {
			color: $gray3;
			font-size: 17px;
			line-height: 20px;
			text-decoration-line: line-through;
		}
	}
}

.prod__btn {
	.btn {
		&.prod__btn_basket {
			padding: 0;
			width: 130px;
			margin-right: 9px;

			@include min768 {
				width: 224px;
				margin-right: 15px;
			}

            .prod[data-basket="true"] & {
				color: $gray;
				background: #FDF4ED;
				color: $gray2;
			}
		}
		&.prod__btn_like {
			width: 45px;
			height: 45px;
			padding: 0;
			background-color: $graylight;

			&:hover {
				svg {
					opacity: 0.5;
				}
			}

			span {
				display: inline-block;
				height: 45px;
				width: 100%;
			}

			.ctrl_like {
				display: flex;
				align-items: center;
				justify-content: center;

                .prod[data-like="true"] & {
					display: none;
				}
			}
			.ctrl_unlike {
				display: none;

                .prod[data-like="true"] & {
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
		}
	}
}

.prod__bonus {
	text-transform: uppercase;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	display: flex;
	align-items: center;

	.prod__bonus_ico {
		display: inline-block;
		border-radius: 50%;
		width: 20px;
		height: 20px;
		line-height: 20px;
		text-align: center;
		color: #fff;
		background-color: $gray;
		margin-right: 5px;
	}

	.prod__bonus_desc {
		display: inline-block;
		margin-right: 10px;
	}
}

.prod__info {
	font-size: 11px;
	line-height: 15px;
	margin-bottom: 30px;
}

.prod__info-item {
	overflow-wrap: break-word;
	word-break: break-word;
	margin-bottom: 10px;

	@include min768 {
		width: 224px;
	}

	.prod__info-item_name {
		display: inline-block;
		width: 60%;
		color: #333333;
		margin: 0 2px 0 0;
		position: relative;
		vertical-align: top;

		b {
			background: #fff;
			padding: 0 2px 0 0;
			position: relative;
			z-index: 1;
		}

		&::after {
			content: '';
			border-bottom: 1px dotted #ccc;
			bottom: 4px;
			display: block;
			height: 20px;
			left: 0;
			position: absolute;
			width: 100%;
		}
	}

	.prod__info-item_value {
		overflow-wrap: break-word;
		word-break: break-word;
		display: inline-block;
		vertical-align: bottom;
		width: 39%;
	}
}

.prod__tags {

	.tag__item {
		padding: 0 12px;

		@include min768 {
			height: 25px;
			line-height: 25px;
			font-size: 11px;
			color: $gray;
			background-color: $graylight;

			&:hover {
				color: $gray2;
				background-color: rgba($graylight, .75);
			}	
		}
	}
}

.prod__tags-title {
	font-size: 17px;
	line-height: 20px;
	margin-bottom: 15px;
}

.prod__label {

	&.m-desktop {
		display: none;
		@include min768 {
			display: flex;
			align-items: center;
			margin-bottom: 30px;
		}
	}
}

.prod__label-item {
	font-weight: 500;
	font-size: 14px;
	text-transform: uppercase;
	display: inline-block;
	height: 20px;
	line-height: 20px;
	text-align: center;
	border-radius: 10px;
	padding: 0 12px;
	color: #fff;
	margin-right: 10px;

	&.prod__label-item_new {
		background-color: #50ACE0;
	}
	&.prod__label-item_discount {
		background-color: $red;
	}
	&.prod__label-item_hit {
		background-color: #B2E050;
	}

	&.prod__label-item_rating {
		color: $gray2;
		width: 120px;
		padding-left: 100px;
		font-weight: 400;
		height: 15px;
		line-height: 15px;
		background-image: url(/htdocs/i/stars/mark_0.svg);
		background-repeat: no-repeat;
		background-position: 0 50%;
		background-size: contain;
		margin-top: 10px;
		

		&[data-rating="1"] {
			background-image: url(/htdocs/i/stars/mark_1.svg);
		}
		&[data-rating="2"] {
			background-image: url(/htdocs/i/stars/mark_2.svg);
		}
		&[data-rating="3"] {
			background-image: url(/htdocs/i/stars/mark_3.svg);
		}
		&[data-rating="4"] {
			background-image: url(/htdocs/i/stars/mark_4.svg);
		}
		&[data-rating="5"] {
			background-image: url(/htdocs/i/stars/mark_5.svg);
		}

		@include min375 {
			margin-top: 0;
			margin-left: 10px;
		}
	}

	&:first-child {
		margin-left: 0;
	}
}

.prod__content {
	position: relative;
	min-height: 376px;

	&.m-bonus {
		min-height: 300px;
	}
	
	@include min768 {
		padding-right: 310px;
	}
}

.prod__content-cell {
	.prod__btn {
		margin: 0 auto 22px;
		width: 100%;
		@include min768 {
			width: 285px;
		}
		.btn {
			width: 100%;
		}
	}

	.prod__bonus {
		justify-content: center;
	}

	&.m-aside {
		margin-bottom: 44px;

		@include min768 {
			position: absolute;
			right: 0;
			top: 0;
		}
	}
}