.comment {
	max-width: 1120px;
	margin-bottom: 50px;
}

.comment__in {
	position: relative;
}

.comment__title {
	position: relative;
	margin-bottom: 35px;
}

.comment__form {
	padding-bottom: 80px;
	max-width: 620px;
	font-size: 14px;
	line-height: 20px;
	display: none;

	.comment.m-show-form & {
		display: block;
	}

	.text-area {
        resize: none;
		min-height: 100px;
        width: 100%;
	}
}

.comment__form-desc {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 8px;
	font-size: 17px;
}

.comment__form-rating {
	margin-bottom: 30px;

	.rating-stars {
		display: block;
		background-repeat: no-repeat;
		background-position: 0 50%;
		height: 38px;
		width: 100%;
		margin-bottom: 15px;
		padding-top: 2px;

		.star {
			display: inline-block;
			width: 40px;
			height: 40px;
			// background-image: url(/htdocs/i/stars/star.svg);
			background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M40 14.48L25.62 13.24L20 0L14.38 13.26L0 14.48L10.92 23.94L7.64 38L20 30.54L32.36 38L29.1 23.94L40 14.48Z' fill='%23CCCCCC'/%3E%3C/svg%3E");
			background-repeat: no-repeat;
			background-position: 50%;
			background-size: contain;
			margin-right: 10px;
			cursor: pointer;
			transition: opacity .15s ease;

			&.star_state_active {
				// background-image: url(/htdocs/i/stars/star_yellow.svg);
				background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M40 14.48L25.62 13.24L20 0L14.38 13.26L0 14.48L10.92 23.94L7.64 38L20 30.54L32.36 38L29.1 23.94L40 14.48Z' fill='%23FFB82F'/%3E%3C/svg%3E");
			}

			&:hover {
				opacity: 0.75;
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}
}

.comment__btn {

	&.btn {
		font-size: 14px;
		padding: 0;
		min-width: 200px;
		line-height: 35px;
		height: 35px;
	}

	&.comment__btn-type_close {
		padding: 0;
		width: 45px;
		height: 45px;
		background-color: #fff;
		min-width: inherit;
		padding-top: 6px;

		@include min768 {
			background-color: $graylight;
		}
		svg {
			width: 14px;
			height: 14px;
		}

		&:hover {
			svg {
				opacity: .5;
			}
		}
	}
}

.comment__item {
	margin-bottom: 27px;

	&:last-child {
		margin-bottom: 0;
	}
	&:nth-child(2n) {
		.comment__item-ava {
			background-color: #83DCB7;
		}
	}
	&:nth-child(3n) {
		.comment__item-ava {
			background-color: #DCD383;
		}
	}
	&:nth-child(4n) {
		.comment__item-ava {
			background-color: #DC8883;
		}
	}
	&:nth-child(5n) {
		.comment__item-ava {
			background-color: #DC83C3;
		}
	}
}

.comment__item-header {
	display: flex;
	align-items: flex-start;
	margin-bottom: 7px;
}

.comment__item-ava {
	min-width: 60px;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	background-color: #DC83C3;
	color: #fff;
	font-weight: 700;
	font-size: 35px;
	line-height: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 10px;
	overflow: hidden;

	img {
		max-width: 100%;
		height: auto;
		border-radius: 50%;
	}
}

.comment__item-info {}

.comment__item-info-in {
	display: block;
}

.comment__item-author {
	font-weight: 700;
	font-size: 14px;
	line-height: 25px;
	text-transform: uppercase;
}

.comment__item-date {
	font-size: 11px;
}

.comment__item-body {
	font-size: 11px;
	line-height: 16px;
	padding-top: 12px;

	p {
		margin-bottom: 10px;
		&:last-child {
			margin-bottom: 0;
		}
	}
}

.comment__item-rating {
	margin-top: 5px;
	background-image: url(/htdocs/i/stars/mark_2.svg);
	background-repeat: no-repeat;
	height: 15px;
	width: 100px;
	font-size: 0;

	&[data-rating="1"] {
		background-image: url(/htdocs/i/stars/mark_1.svg);
	}
	&[data-rating="2"] {
		background-image: url(/htdocs/i/stars/mark_2.svg);
	}
	&[data-rating="3"] {
		background-image: url(/htdocs/i/stars/mark_3.svg);
	}
	&[data-rating="4"] {
		background-image: url(/htdocs/i/stars/mark_4.svg);
	}
	&[data-rating="5"] {
		background-image: url(/htdocs/i/stars/mark_5.svg);
	}
}

.comment__btn-wrap {
	padding-top: 30px;
	.btn {
		width: 100%;
		@include min768 {
			width: 140px;
		}
	}
}