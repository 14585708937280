@mixin min340 {
    @media only screen and (min-width: 340px) {
        @content;
    }
}

@mixin min375 {
    @media only screen and (min-width: 375px) {
        @content;
    }
}

@mixin min400 {
    @media only screen and (min-width: 400px) {
        @content;
    }
}

@mixin min415 {
    @media only screen and (min-width: 415px) {
        @content;
    }
}

@mixin min480 {
    @media only screen and (min-width: 480px) {
        @content;
    }
}

@mixin min640 {
    @media only screen and (min-width: 640px) {
        @content;
    }
}

@mixin min720 {
    @media only screen and (min-width: 720px) {
        @content;
    }
}

@mixin min768 {
    @media only screen and (min-width: 768px) {
        @content;
    }
}

@mixin max767 {
    @media only screen and (max-width: 767px) {
        @content;
    }
}

@mixin min800 {
    @media only screen and (min-width: 800px) {
        @content;
    }
}

@mixin min900 {
    @media only screen and (min-width: 900px) {
        @content;
    }
}

@mixin min960 {
    @media only screen and (min-width: 960px) {
        @content;
    }
}

@mixin min1024 {
    @media only screen and (min-width: 1024px) {
        @content;
    }
}

@mixin min1080 {
    @media only screen and (min-width: 1080px) {
        @content;
    }
}

@mixin min1160 {
    @media only screen and (min-width: 1160px) {
        @content;
    }
}

@mixin min1200 {
    @media only screen and (min-width: 1200px) {
        @content;
    }
}

@mixin min1320 {
    @media only screen and (min-width: 1320px) {
        @content;
    }
}

@mixin min1600 {
    @media only screen and (min-width: 1600px) {
        @content;
    }
}

@mixin mix($rule) {
  @media only screen and #{$rule} {
      @content;
  }
}
