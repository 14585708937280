@import '../utils/var';
@import '../utils/mx';
@import '../utils/mq';


.prod {
    .txt {
        margin-bottom: 50px;
        @include min768 {
            margin-bottom: 55px;
        }

        .btn {
            padding: 0 21px;
        }
    }
}

body.m--product {
    .page {
        margin-bottom: 54px;
    }
}
@import '../blocks/comments';
@import '../blocks/product/product';
@import '../blocks/product/product_rt';
