$textColor : #333;

// colors
$main      : #FFA8AF;
$gray      : #333;
$gray2     : #666666;
$gray3     : #ccc;
$graylight : #F2F2F2;
$red       : #E05050;
$yellow    : #FFB82F;
$pink      : #FFA8AF;
$pinklight : #FFCCD0;

// удалить
$bluelight : #E7F3FE;
