.prod-rt {
	width: 100%;
	padding: 28px 20px 35px;
	margin: 0 auto 20px;

	@include min768 {
		width: 285px;
	}
}

.prod-rt__grade {
	font-size: 25px;
	line-height: 29px;
	text-align: center;
	margin-bottom: 10px;
}

.prod-rt__title {
	font-size: 21px;
	line-height: 25px;
	margin-bottom: 20px;
	text-align: center;
}

.prod-rt__stars {
	height: 20px;
	margin-bottom: 10px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: 50%;
	background-image: url(/htdocs/i/stars/mark_2.svg);

	&[data-rating="1"] {
		background-image: url(/htdocs/i/stars/mark_1.svg);
	}
	&[data-rating="2"] {
		background-image: url(/htdocs/i/stars/mark_2.svg);
	}
	&[data-rating="3"] {
		background-image: url(/htdocs/i/stars/mark_3.svg);
	}
	&[data-rating="4"] {
		background-image: url(/htdocs/i/stars/mark_4.svg);
	}
	&[data-rating="5"] {
		background-image: url(/htdocs/i/stars/mark_5.svg);
	}

	.prod__content.m-bonus & {
		background-image: url(/htdocs/i/stars/triple.svg);
		height: 38px;
		margin-bottom: 22px;
	}
}

.prod-rt__desc {
	font-size: 11px;
	line-height: 13px;
	margin-bottom: 29px;
	text-align: center;

	.prod__content.m-bonus & {
		margin-bottom: 0;
	}
}


.prod-rt__list {

}

.prod-rt__item {
	display: flex;
	align-items: center;
	font-size: 11px;
	line-height: 13px;
	margin-bottom: 5px;

	&:last-child {
		margin-bottom: 0;
	}

	span {
		display: inline-block;

		&.prod-rt__item_icon {
			display: flex;
			align-items: center;
			margin-left: 5px;
		}

		&.prod-rt__item_progress {
			margin: 0 10px;
			width: 186px;
			width: calc( 100% - 40px );
			height: 6px;
			border-radius: 7px;
			background-color: $graylight;
			position: relative;

			span.prod-rt__item_progress_in {
				display: inline-block;
				height: 6px;
				background-color: $pink;
				position: absolute;
				top: 0;
				left: 0;
				border-radius: 7px;
			}
		}

		&.prod-rt__item_count {
			display: inline-block;
			width: 15px;
		}
	}
}